/**
 * Default page javascript extended from sanofi-lm-platform.
 * All other pages in sanofi-lm-emr extend this one.
 */

 // Styles
 import "../../css/pages/LoginPage.scss";
 
 // Libs
 import EmrBasePage from "./EmrBasePage";
 
 class EmrLoginPage extends EmrBasePage {
     constructor(...opts) {
         super(...opts);
     }
 
     init() {
         super.init();
     }
 
     onReady() {
         super.onReady();
     }
 }
 
 export default EmrLoginPage;
 