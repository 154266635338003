import EmrLoginPage from "../modules/loginPage.js";

class LoginPage extends EmrLoginPage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new LoginPage();
